import './CaseStudyHero.scss';

import React from 'react';

import Image from '../../../components/Image/Image';
import ScrollTrigger from '../../../components/ScrollTrigger/ScrollTrigger';
import Section from '../../../components/Section/Section';

const CaseStudyHero = ({ headIMG = {}, logo: { alt, url } = {} }) => {
  if (!headIMG.fluid && !url) {
    return null;
  }
  return (
    <Section className="case-study-hero" isFullHeight isNoSpaced>
      {headIMG.fluid && (
        <div className="case-study-hero__image-wrapper">
          <Image {...headIMG} />
        </div>
      )}
      <div className="case-study-hero__icon">
        <ScrollTrigger>{url && <img alt={alt} src={url} />}</ScrollTrigger>
      </div>
    </Section>
  );
};

export default CaseStudyHero;
