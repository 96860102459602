import './CaseStudyS5.scss';

import React from 'react';

import Col from '../../../components/Col/Col';
import Gallery from '../../../components/Gallery/Gallery';
import Row from '../../../components/Row/Row';
import ScrollTrigger from '../../../components/ScrollTrigger/ScrollTrigger';
import Section from '../../../components/Section/Section';

const CaseStudyS5 = ({ items = [] }) => {
  if (!items.length) {
    return null;
  }

  return (
    <Section className="case-study-s5">
      <Row mdJustify="center">
        <Col xx={12} md={10}>
          <ScrollTrigger>
            <Gallery items={items} />
          </ScrollTrigger>
        </Col>
      </Row>
    </Section>
  );
};

export default CaseStudyS5;
