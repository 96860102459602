import './CaseStudyS3.scss';

import { RichText } from 'prismic-reactjs';
import React from 'react';

import Col from '../../../components/Col/Col';
import Heading from '../../../components/Heading/Heading';
import Row from '../../../components/Row/Row';
import ScrollTrigger from '../../../components/ScrollTrigger/ScrollTrigger';
import Section from '../../../components/Section/Section';

const CaseStudyS3 = ({ descRT = {}, headingRT = {} }) => {
  if (!headingRT.text && !descRT.text) {
    return null;
  }

  return (
    <Section className="case-study-s3">
      <Row>
        {/* left */}
        <Col className="case-study-s3__left" xx={12} md={4} mdOffset={1}>
          <ScrollTrigger>
            {headingRT.text && (
              <Heading>
                <RichText render={headingRT.raw} />
              </Heading>
            )}
          </ScrollTrigger>
        </Col>
        {/* right */}
        <Col xx={12} md={5} mdOffset={1}>
          <ScrollTrigger desktopSet={{ to: { delay: 0.4 } }}>
            {descRT.text && (
              <Heading>
                <RichText render={descRT.raw} />
              </Heading>
            )}
          </ScrollTrigger>
        </Col>
      </Row>
    </Section>
  );
};

export default CaseStudyS3;
