import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';
import { get } from 'lodash';
import React from 'react';

import CaseStudyHero from './CaseStudyHero/CaseStudyHero';
import CaseStudyS1 from './CaseStudyS1/CaseStudyS1';
import CaseStudyS2 from './CaseStudyS2/CaseStudyS2';
import CaseStudyS3 from './CaseStudyS3/CaseStudyS3';
import CaseStudyS4 from './CaseStudyS4/CaseStudyS4';
import CaseStudyS5 from './CaseStudyS5/CaseStudyS5';
import CaseStudyS6 from './CaseStudyS6/CaseStudyS6';
import CaseStudyS7 from './CaseStudyS7/CaseStudyS7';

const CaseStudyPage = ({
  data: {
    page: {
      data: {
        hero_image,
        partner,
        section_1_title,
        section_2_desc,
        section_2_image,
        section_3_desc,
        section_3_title,
        section_4_image,
        section_4_desc,
        section_4_table_rows,
        section_5_images,
        section_6_desc,
        section_6_title,
        section_7_image,
        section_7_table_header_text,
        section_7_table_rows,
      },
    } = {},
  } = {},
}) => {
  return (
    <React.Fragment>
      {/* Hero */}
      <CaseStudyHero headIMG={hero_image} logo={get(partner, 'document.data.logo', {})} />

      {/* Section 1 */}
      <CaseStudyS1 headingRT={section_1_title} />

      {/* Section 2 */}
      <CaseStudyS2 headingRT={section_2_desc} headIMG={section_2_image} />

      {/* Section 3 */}
      <CaseStudyS3 descRT={section_3_desc} headingRT={section_3_title} />

      {/* Section 4 */}
      <CaseStudyS4 headIMG={section_4_image} descRT={section_4_desc} tableRows={section_4_table_rows} />

      {/* Section 5 */}
      <CaseStudyS5 items={section_5_images} />

      {/* Section 6 */}
      <CaseStudyS6 descRT={section_6_desc} headingRT={section_6_title} />

      {/* Section 7 */}
      <CaseStudyS7
        headIMG={section_7_image}
        tableHeaderRT={section_7_table_header_text}
        tableRows={section_7_table_rows}
      />
    </React.Fragment>
  );
};

export default withPreview(CaseStudyPage);

export const query = graphql`
  query($id: String, $lang: String) {
    page: prismicCaseStudyPage(id: { eq: $id }, lang: { eq: $lang }) {
      data {
        partner {
          document {
            ... on PrismicPartner {
              data {
                logo {
                  alt
                  url
                }
              }
            }
          }
        }

        hero_image {
          alt
          fluid(maxWidth: 1440) {
            ...GatsbyPrismicImageFluid_withWebp
          }
        }

        section_1_title {
          raw
          text
        }

        section_2_desc {
          raw
          text
        }
        section_2_image {
          alt
          fluid(maxWidth: 1100) {
            ...GatsbyPrismicImageFluid_withWebp
          }
        }

        section_3_desc {
          raw
          text
        }
        section_3_title {
          raw
          text
        }

        section_4_desc {
          raw
          text
        }
        section_4_image {
          alt
          fluid(maxWidth: 520) {
            ...GatsbyPrismicImageFluid_withWebp
          }
        }
        section_4_table_rows {
          text {
            raw
            text
          }
          value
          value_type
        }

        section_5_images {
          image {
            alt
            fluid(maxWidth: 880) {
              ...GatsbyPrismicImageFluid_withWebp
            }
            url
          }
        }

        section_6_desc {
          raw
          text
        }
        section_6_title {
          raw
          text
        }

        section_7_image {
          alt
          fluid(maxWidth: 410) {
            ...GatsbyPrismicImageFluid_withWebp
          }
        }
        section_7_table_header_text {
          raw
          text
        }
        section_7_table_rows {
          text {
            raw
            text
          }
        }
      }
    }
  }
`;
