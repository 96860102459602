import './CaseStudyS1.scss';

import { RichText } from 'prismic-reactjs';
import React from 'react';

import Col from '../../../components/Col/Col';
import Heading from '../../../components/Heading/Heading';
import Row from '../../../components/Row/Row';
import ScrollTrigger from '../../../components/ScrollTrigger/ScrollTrigger';
import Section from '../../../components/Section/Section';

const CaseStudyS1 = ({ headingRT = {} }) => {
  if (!headingRT.text) {
    return null;
  }

  return (
    <Section className="case-study-s1">
      <Row mdJustify="center">
        <Col xx={12} md={8}>
          <ScrollTrigger>
            <Heading>
              <RichText render={headingRT.raw} />
            </Heading>
          </ScrollTrigger>
        </Col>
      </Row>
    </Section>
  );
};

export default CaseStudyS1;
