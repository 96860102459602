import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

import './Gallery.scss';

import React from 'react';
import SwiperCore, { A11y, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwipeArrow from '../../images/swipe-arrow.svg';
import Image from '../Image/Image';

SwiperCore.use([Navigation, A11y, Pagination]);

const Gallery = ({ items }) => {
  return (
    <div className="gallery">
      <div className="gallery__top">
        <Swiper
          className="gallery__swiper"
          navigation={{
            disabledClass: 'gallery__arrow--hidden',
            el: '.gallery__arrow',
            nextEl: '.gallery__arrow--right',
            prevEl: '.gallery__arrow--left',
          }}
          pagination={{
            clickable: true,
            el: '.gallery__pagination',
            type: 'fraction',
          }}
          slidesPerView={1}
          speed={300}
        >
          {items.map(
            ({ image }) =>
              image.fluid && (
                <SwiperSlide key={image.url}>
                  <div className="gallery__slide">
                    <Image {...image} />
                  </div>
                </SwiperSlide>
              )
          )}
        </Swiper>
      </div>
      <div className="gallery__bottom">
        <div className="gallery__pagination" />
        <div className="gallery__navigation">
          <button className="gallery__arrow gallery__arrow--left" type="button">
            <img alt="" src={SwipeArrow} />
          </button>
          <span className="gallery__navigation--text">Swipe</span>
          <button className="gallery__arrow gallery__arrow--right" type="button">
            <img alt="" src={SwipeArrow} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
