import './CaseStudyS2.scss';

import { RichText } from 'prismic-reactjs';
import React from 'react';

import Col from '../../../components/Col/Col';
import Heading from '../../../components/Heading/Heading';
import Image from '../../../components/Image/Image';
import Row from '../../../components/Row/Row';
import ScrollTrigger from '../../../components/ScrollTrigger/ScrollTrigger';
import Section from '../../../components/Section/Section';

const CaseStudyS2 = ({ headingRT = {}, headIMG = {} }) => {
  if (!headingRT.text && !headIMG.fluid) {
    return null;
  }

  return (
    <Section className="case-study-s2">
      <Row mdJustify="center">
        <Col xx={12} md={10}>
          <ScrollTrigger>{headIMG.fluid && <Image {...headIMG} />}</ScrollTrigger>
          <ScrollTrigger>
            {headingRT.text && (
              <Heading>
                <RichText render={headingRT.raw} />
              </Heading>
            )}
          </ScrollTrigger>
        </Col>
      </Row>
    </Section>
  );
};

export default CaseStudyS2;
