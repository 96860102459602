import './CaseStudyS4.scss';

import { RichText } from 'prismic-reactjs';
import React, { useState } from 'react';

import Col from '../../../components/Col/Col';
import Counter from '../../../components/Counter/Counter';
import Image from '../../../components/Image/Image';
import Row from '../../../components/Row/Row';
import ScrollTrigger from '../../../components/ScrollTrigger/ScrollTrigger';
import Section from '../../../components/Section/Section';
import Table from '../../../components/Table/Table';
import TableRow from '../../../components/Table/TableRow';
import Text from '../../../components/Text/Text';

const CaseStudyS4 = ({ descRT = {}, headIMG = {}, tableRows = [] }) => {
  if (!headIMG.fluid && !tableRows.length) {
    return null;
  }

  const [isTableVisible, setIsTableVisible] = useState(false);

  const handleTableScrollEnter = () => {
    setIsTableVisible(true);
  };

  return (
    <Section className="case-study-s4">
      <Row>
        <Col xx={12} md={5} mdOffset={1} className="case-study-s4--left">
          <ScrollTrigger>
            {headIMG.fluid && <Image {...headIMG} />}
            {descRT.text && (
              <Text>
                <RichText render={descRT.raw} />
              </Text>
            )}
          </ScrollTrigger>
        </Col>
        <Col xx={9} xxOffset={3} md={4} mdOffset={1} className="case-study-s4--right">
          <ScrollTrigger allSet={{ to: { scrollTrigger: { onEnter: handleTableScrollEnter } } }}>
            {tableRows.length > 0 && (
              <Table>
                {tableRows.map((row) => (
                  <TableRow key={row.text.text + row.value}>
                    <Counter value={isTableVisible && row.value} type={row.value_type} />
                    <Text>
                      <RichText render={row.text.raw} />
                    </Text>
                  </TableRow>
                ))}
              </Table>
            )}
          </ScrollTrigger>
        </Col>
      </Row>
    </Section>
  );
};

export default CaseStudyS4;
